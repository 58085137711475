// development server

// export const baseUrl = "http://127.0.0.1:8000/";
// export const baseUrlHashless = "http://127.0.0.1:8000";
// export const baseMediaUrl = "http://127.0.0.1:8000/media/";

// production server

export const baseUrl = "https://sightseeingroma.pythonanywhere.com/";
export const baseUrlHashless = "https://sightseeingroma.pythonanywhere.com";
export const baseMediaUrl = "https://sightseeingroma.pythonanywhere.com/media/";