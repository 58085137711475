import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Carousel from "react-multi-carousel";
import { useNavigate, useParams } from "react-router-dom";
import custom_loader from "../assets/custom_loader.gif";
import BigLoader from "../components/BigLoader";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MobileButtonSet from "../components/MobileButtonSet";
import Title from "../components/Title";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";
import scrollToTop from "../utilities/scrollToTop";
import handleStripeCheckout from "../utilities/stripeCheckout";

export default function Details() {
    const navigate = useNavigate();

    const [checkoutDissabled, setCheckoutDissabled] = useState(true);
    const [bigLoader, setBigLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    // for suggestions part
    const [busPackages, setBusPackages] = useState([]);

    const { packageId, status } = useParams();
    const [data, setData] = useState({})

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        },
        mobile2: {
          breakpoint: { max: 430, min: 0 },
          items: 2
        }
    };

    useEffect(() => {
        scrollToTop();

        fetch(`${baseUrl}package/${status}/${packageId}/`)
            .then(response => response.json())
            .then(data => {
                setData(data.data);
                
                setCheckoutDissabled(true);
                document.getElementById("grand_total").innerHTML = `€0`;

                const itemList = [
                    {
                      id: packageId,
                      name: data.data.title,
                      brand: "Sightseeingroma",
                      category: "Tourism",
                      price: data.data.adult_price,
                      quantity: 1
                    },
                  ];
                  
                  ReactGA.event({
                    category: 'Ecommerce',
                    action: 'view_item_list',
                    value: itemList.reduce((sum, item) => sum + item.price * item.quantity, 0),
                    currency: 'EUR',
                    'items': itemList,
                  });
        });

        fetch(baseUrl + "packages/")
            .then(response => response.json())
            .then(data => {
                setBusPackages(data.bus_data);
                // setMuseumPackages(data.museum_data);
                // setFolders(data.folders);
        })

    }, [])

    const sanitizedHtml = DOMPurify.sanitize(data.description);

    // let selectedDate = ''
    const [selectedDate, setSelectedDate] = useState('')

    function formatDate(inputDate) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
      
        const dateObj = new Date(inputDate);
        const day = dateObj.getDate();
        const monthAbbreviation = months[dateObj.getMonth()];
      
        return [day, monthAbbreviation, generateRandomString(), inputDate];
    }

    let formattedDates = [];

    if (data.dates) {
        formattedDates = data.dates.map(date => formatDate(date));
    }

    const [adult_count, setAdultCount] = useState(0);
    const [youth_count, setYouthCount] = useState(0);
    const [infant_count, setInfantCount] = useState(0);

    let grandTotal = 0;

    useEffect(() => {
        let adult_total = adult_count * data.adult_price;
        let youth_total = youth_count * data.youth_price;
        let infant_total = infant_count * data.infant_price;

        grandTotal = adult_total + youth_total + infant_total;

        if (grandTotal === 0) {
            setCheckoutDissabled(true);
        } else {
            setCheckoutDissabled(false);
        }

        document.getElementById("grand_total").innerHTML = `€${grandTotal}`;
    }, [adult_count, youth_count, infant_count])

    function generateRandomString() {
        const firstFiveLetters = 'abcde';
        const remainingLetters = 'abcdefghijklmnopqrstuvwxyz';
        let randomString = '';
    
        for (let i = 0; i < 5; i++) {
            randomString += firstFiveLetters.charAt(Math.floor(Math.random() * firstFiveLetters.length));
        }
    
        randomString += '_';

        for (let i = 0; i < 3; i++) {
            randomString += remainingLetters.charAt(Math.floor(Math.random() * remainingLetters.length));
        }
    
        return randomString;
    }

    const tempElement = document.createElement('div');
    tempElement.innerHTML = data.description;

    const cleanText = tempElement.textContent || tempElement.innerText;

    function handleStripeCheckoutFunction(adult_count_parameter, youth_count_parameter, infant_count_parameter) {
        if (data.dates && selectedDate === '') {
            setMessage("Please select a date first");
            setShowMessage(true);
        } else {
            setMessage('');
            setShowMessage(false);
            setBigLoader(true);
            handleStripeCheckout(data.title, cleanText, baseUrlHashless+data.image_big, selectedDate, adult_count_parameter, youth_count_parameter, infant_count_parameter, navigate, packageId, status, setBigLoader);

        }
    }

    return(
        <>

        {bigLoader && <BigLoader />}
        
        <Header />

        <div className="container details_container">
            <div className="text_section">
                <span>{data.type}</span>
                <h1>{data.title}</h1>
                <span>{data.duration}</span>
            </div>

            <div className="row my-3 image_section">
                <div className="col-md-7 first_image">
                    {data.image_big ? <img src={baseUrlHashless+data.image_big} alt="image" /> : <img src={custom_loader} alt="image" />}
                </div>
                
                <div className="col-md-5 image_section">
                    <div className="photo-gallery">
                        {data.second_image ? <img src={baseUrlHashless+data.second_image} alt="image" /> : <img src={custom_loader} alt="image" />}
                        {data.third_image ? <img src={baseUrlHashless+data.third_image} alt="image" /> : <img src={custom_loader} alt="image" />}
                        {data.fourth_image ? <img src={baseUrlHashless+data.fourth_image} alt="image" /> : <img src={custom_loader} alt="image" />} 

                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-md-7 description_box my-2">
                    <h1>Description</h1>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                </div>

                <div className="col-md-5 ticket_box my-2">
                    <div className="ticket_body d-flex flex-column">
                        <div className="ticket_price">
                            <span>From €{data.off_price}</span>
                            <h4>From €{data.adult_price} per person</h4>
                            <p>Buy your tickets from here. Pay online using your cards, get a QR code, show the QR code at one of our meeting points and collect a printout of your ticket from our agents.</p>
                        </div>

                        <div className="price_box d-flex justify-content-between align-items-center my-1">
                            <div className="price_segment d-flex justify-content-center align-items-center first_segment">Adult <br />(19-99)</div>
                            {/* <input type="number" id="adult_element" min={0} onChange={e => {document.getElementById("adult_total").value = `$${e.target.value*data.adult_price}`; adult_count=e.target.value; setGrandTotal() }} className="price_segment" defaultValue={0} /> */}
                            <input type="number" id="adult_element" min={0} onChange={e => {document.getElementById("adult_total").value = `€${e.target.value*data.adult_price}`; setAdultCount(e.target.value); }} className="price_segment" value={adult_count} />
                            
                            <p className="desktop_multiple_sign">X</p>
                            
                            <MobileButtonSet state={adult_count} setState={setAdultCount} />

                            <input type="text" className="price_segment" value={`€${data.adult_price}`} disabled />
                            <input type="text" id="adult_total" className="price_segment calculated" defaultValue={`€0`} disabled />
                        </div>
                        
                        <div className="price_box d-flex justify-content-between align-items-center my-1">
                            <div className="price_segment d-flex justify-content-center align-items-center first_segment">Youth <br />(6-18)</div>
                            {/* <input type="number" id="youth_element" min={0} onChange={e => {document.getElementById("youth_total").value = `$${e.target.value*data.youth_price}`; youth_count=e.target.value; setGrandTotal()  }} className="price_segment" defaultValue={0} /> */}
                            <input type="number" id="youth_element" min={0} onChange={e => {document.getElementById("youth_total").value = `€${e.target.value*data.youth_price}`; setYouthCount(e.target.value); }} className="price_segment" value={youth_count} />
                            <p className="desktop_multiple_sign">X</p>

                            <MobileButtonSet state={youth_count} setState={setYouthCount} />

                            <input type="text" className="price_segment" value={`€${data.youth_price}`} disabled />
                            <input type="text" id="youth_total" className="price_segment calculated" defaultValue={"€0"} disabled />
                        </div>
                        
                        <div className="price_box d-flex justify-content-between align-items-center my-1">
                            <div className="price_segment d-flex justify-content-center align-items-center first_segment">Infant <br />(0-5)</div>
                            {/* <input type="number" id="infant_element" min={0} onChange={e => {document.getElementById("infant_total").value = `$${e.target.value*data.infant_price}`; infant_count=e.target.value; setGrandTotal() }} className="price_segment" defaultValue={0} /> */}
                            <input type="number" id="infant_element" min={0} onChange={e => {document.getElementById("infant_total").value = `€${e.target.value*data.infant_price}`; setInfantCount(e.target.value); }} className="price_segment" value={infant_count} />
                            <p className="desktop_multiple_sign">X</p>

                            <MobileButtonSet state={infant_count} setState={setInfantCount} />

                            <input type="text" className="price_segment" value={`€${data.infant_price}`} disabled />
                            <input type="text" id="infant_total" className="price_segment calculated" defaultValue={"€0"} disabled />
                        </div>

                        { formattedDates ? (<div className="total_price schedule my-2">
                            {data.dates && <span>Pick a Date</span>}

                            <form>

                                {formattedDates.map(item =>
                                    <>
                                    <input type="radio" value={item[3]} id={item[2]} className="radio" name="dates" />
                                    {/* <label value={item[3]} htmlFor={item[2]} onClick={() => {selectedDate = item[3]; setMessage(''); setShowMessage(false);}} className="label">{item[0]} <br /> {item[1]}</label> */}
                                    <label value={item[3]} htmlFor={item[2]} onClick={() => {setSelectedDate(item[3]); setMessage(''); setShowMessage(false);}} className="label">{item[0]} <br /> {item[1]}</label>
                                    </>
                                )}

                            </form>
                        </div>) : <span></span>}

                        <div className="total_price my-2">
                            <span>Total price</span>
                            <h4 id="grand_total">€0</h4>
                        </div>

                        {showMessage && <p id="error_message">{message}</p>}

                        { checkoutDissabled ? <button disabled={true}>checkout</button> : <button onClick={() => handleStripeCheckoutFunction(document.getElementById("adult_element").value, document.getElementById("youth_element").value, document.getElementById("infant_element").value)}>checkout</button> }
                    </div>
                </div>
            </div>
        </div>

        <div className="suggestions_container container">
            <Title text="suggestions" />

            <div className="my-3">
            </div>
            <Carousel infinite={true} autoPlaySpeed={5000} autoPlay={true} responsive={responsive}>
                    {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.ticket_type === data.ticket_type && package_item.company !== data.company && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
            </Carousel>

        </div>

        <Footer />

        </>
    )
}