import { useNavigate } from "react-router-dom";

export default function Card(props) {
    var navigate = useNavigate();

    let status = null;

    if (props.has_dates) {
        status = "E8";
    } else {
        status = "E9";
    }

    return (
        <div className="shadow card_body d-flex flex-column">
            <img onClick={() => window.open(`/details/${status}/${props.package_tag}`, '_blank')} src={props.image} alt="card_img" />

            <div onClick={() => window.open(`/details/${status}/${props.package_tag}`, '_blank')} className="name mt-2">
            {/* <div onClick={() => navigate(`/details/${status}/${props.package_tag}`)} className="name mt-2"> */}
                <span>{props.type}</span>
                <h1>{props.name} <span className="card_id_tag">#{props.card_id}</span></h1>
                <span>{props.time}</span>
            </div>

            <div onClick={() => window.open(`/details/${status}/${props.package_tag}`, '_blank')} className="card_price d-flex flex-column my-3">
                <span className="striked">From €{props.striked}</span>
                <span className="real_price">From €{props.real} per person</span>
            </div>

            <div className="card_button d-flex justify-content-center">
                {/* <a onClick={() => navigate(`/details/${props.package_tag}`)}>view details</a> */}
                <a onClick={() => window.open(`/details/${status}/${props.package_tag}`, '_blank')}>view details</a>
            </div>

            {props.children}

        </div>
    )
}