import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import check from "../assets/check.png";
import Header from "../components/Header";
import { baseUrl } from "../utilities/Utilities";

export default function ProcessTicketsV2() {
    const agentRef = useRef(null);

    const {code} = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [agentList, setAgentList] = useState([]);

    const [agentUsername, setAgentUsername] = useState('');
    const [agentCode, setAgentCode] = useState('');

    const [claimData, setClaimData] = useState(false);

    useEffect(() => {
        fetch(`${baseUrl}qr/auth/${code}/`)
            .then(response => response.json())
            .then(data => {
                setData(data);
                setAgentList(data.agent_list);
                console.log(data);
            })
    }, [])

    function handleSubmit(event) {
        event.preventDefault();

        if (event.target.value.toString().length >= 4) {
            agentRef.current.blur();
        }

        fetch(`${baseUrl}claim/${code}/${agentUsername}/${event.target.value}/`)
            .then(response => response.json())
            .then(data => {
                setClaimData(data.success);
            })
    }


    return (
        <>
        
        <Header />

        {data.claimed === false ? <><div className="container d-flex flex-column justify-content-center align-items-center my-5">
            {claimData != true ? <><h4 className="text-center">The ticket has not been claimed yet.</h4>

            <span>Claim the ticket (Agents only)</span>

            <div className="claiming_box">
                <form className="form-group" onSubmit={handleSubmit}>
                    
                <select required className="form-control" name="agent-names" id=""  onChange={event => setAgentUsername(event.target.value)}>
                    <option value="nothing" disabled selected>Select Agent Name</option>
                        {agentList.length !== 0 ? agentList.map((element, index) => (
                            <option key={index} value={element}>{element}</option>
                        )) : <option>Loading...</option>}
                </select>


                    <input required ref={agentRef} onChange={event => {setAgentCode(event.target.value); handleSubmit(event)}} value={agentCode} className={claimData === false ? "form-control" : "form-control success"} type="password" placeholder="Agent Code" />

                    <button disabled={claimData} onClick={handleSubmit} className="form-control" type="submit">Claim Ticket</button>
                </form>
            </div></> 
            
            : 
            
            <>
            
            <div className="after_success d-flex flex-column justify-content-center align-items-center my-3">
                <img className="mb-2" src={check} alt="checked_image" />
                <h4>Ticket claimed successfully!</h4>
            </div>
            
            </>}

            <div className="ticket_info">
                <h5 className="text-center mb-4">Ticket Information</h5>

                {data.data && <div className="process_box d-flex flex-column justify-content-center align-items center">

                <p className="mt-3"><b>Package:</b> {data.data.package} <br />
                <b>Customer Name:</b> {data.data.user} <br />
                <b>Total price:</b> €{data.data.total_price} <br />
                <b>Total Number of Adults:</b> {data.data.adults} <br />
                <b>Total Number of Youth:</b> {data.data.youths} <br />
                <b>Total Number of Infants:</b> {data.data.infants} <br />
                <b>Payment Status:</b> {data.data.paid ? "Paid" : "Unpaid"} <br />
                <b>Selected Date:</b> {data.data.selected_date === null ? "N/A" : data.data.selected_date} <br />
                <b>Package Tag:</b> {data.data.package_tag} <br />
                
                </p>

            </div>}
            </div>
        </div></> 
        
        :
        
        <>
        <div className="container mt-5 ticket_process d-flex justify-content-center align-items-center">
            {data.data && <div className="process_box d-flex flex-column justify-content-center align-items center">
                <h3>{data.msg}</h3>
                <div className="bar"></div>

                <div className=" mt-2 validity">expired</div>

                <p className="mt-3"><b>Package:</b> {data.data.package} <br />
                <b>Customer Name:</b> {data.data.user} <br />
                <b>Total price:</b> €{data.data.total_price} <br />
                <b>Total Number of Adults:</b> {data.data.adults} <br />
                <b>Total Number of Youth:</b> {data.data.youths} <br />
                <b>Total Number of Infants:</b> {data.data.infants} <br />
                <b>Payment Status:</b> {data.data.paid ? "Paid" : "Unpaid"} <br />
                <b>Selected Date:</b> {data.data.selected_date === null ? "N/A": data.data.selected_date} <br />
                <b>Package Tag:</b> {data.data.package_tag} <br />
                
                </p>

            </div>}
        </div>
        
        
        </>
        
        
        }

        </>
    )
}