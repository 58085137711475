import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl } from "../utilities/Utilities";
import scrollToTop from "../utilities/scrollToTop";

export default function TermsAndConditions() {
    scrollToTop();

    const [utilityData, setUtilityData] = useState([]);

    useEffect(() => {
        fetch(baseUrl + "utility/")
            .then(response => response.json())
            .then(data => setUtilityData(data.data[0]))
    }, [])

    const sanitizedHtml = DOMPurify.sanitize(utilityData.terms_and_conditions);

    return (
        <>
        <Header></Header>

        <div className="container my-3">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />

            {/* <h1>Privacy Policy</h1>

            <div className="container d-flex justify-content-center align-items-center my-5">
                <img height="200px" src={under_construction} alt="" />
            </div> */}

        </div>


        <Footer></Footer>
        
        </>
    )
}