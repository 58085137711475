import { useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import loading_img from "../assets/loading.gif";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl } from "../utilities/Utilities";

export default function Login() {
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    function handleLoginSuccess() {
        ReactGA.event({
          category: 'User Authentication',
          action: 'login_success',
          user_id: username,
        });
    }

    const handleClick = (event) => {
        setErrorMessage('');
        setError(false);

        event.preventDefault();
        setLoading(true);

        const header = {
            mode: 'cors',
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        };

        fetch(`${baseUrl}api/token/`, header)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 401) {
                    setErrorMessage("Invalid username or password!");
                    setError(true);
                    setLoading(false);
                    setPassword('');
                }
            })
            .then(data => {
                setLoading(false);
                window.localStorage.setItem('refresh', data.refresh);
                window.localStorage.setItem('access', data.access);
                handleLoginSuccess();
                navigate('/');
            })
            .catch(error => {
                //  
            });

    }

    return(
        <>
        
        <Header />

        
        <div className="container form_section d-flex flex-column justify-content-center align-items-center my-5">
            <h1>Login</h1>

            <form className="form-group" onSubmit={handleClick}>
                <input type="text" onChange={event => setUsername(event.target.value)} value={username} required className="form-control" placeholder="Username" />
                <input type="password" onChange={event => setPassword(event.target.value)} value={password} required className="form-control" placeholder="Password" />

                {error && <p id="error_message">{errorMessage}</p>}
                
                <button disabled={loading} type="submit">{loading ? <img src={loading_img} alt="loading" /> : `submit`}</button>
            </form>

            <div className="footnote">Not registered? <strong onClick={() => navigate(`/registration/`)}>Register</strong></div>
        </div>


        <Footer />

        </>
    )
}