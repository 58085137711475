import error from "../assets/error.png";
// import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Cancel() {
    return(
        <>
        <Header />

        <div className="container-fluid d-flex flex-column justify-content-center align-items-center my-5">
            <div className="success_box d-flex flex-column justify-content-center align-items-center">
                <img src={error} alt="icon" />
                <h1>payment cancelled!</h1>
            </div>
        </div>

        {/* <Footer /> */}
        </>
    )
}