import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";
import scrollToTop from "../utilities/scrollToTop";

export default function FolderPackages() {
    scrollToTop();

    const {id} = useParams();

    const [busPackages, setBusPackages] = useState([])

    useEffect(() => {
        fetch(baseUrl + `packages/${id}/`)
            .then(response => response.json())
            .then(data => {
                setBusPackages(data.bus_data);
            })
    }, [])

    return (
        <>

        <Header />
        
        <div className="main_card_container container">

            <div className="my-5 cards_section d-flex flex-row flex-wrap">
                
                {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(item => <Card has_dates={false} key={item.id} package_tag={item.package_tag} image={baseUrlHashless + item.image_big} type={item.type} name={item.title} time={item.duration} striked={item.off_price} real={item.adult_price} />)}
            
            </div>

        </div>

        <Footer />

        </>
    )
}