import Footer from "../components/Footer";
import Header from "../components/Header";

export default function AgentPoint() {
    return (
        <>
            <Header />

            <div className="container my-3 w-100">
                <h3 className="my-4">Find Our Agent Points</h3>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.9333245360003!2d12.481010600000001!3d41.894290999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f611650921fc1%3A0x815421e4b8ee1399!2sopen%20bus%20tour!5e0!3m2!1sen!2sbd!4v1711543067853!5m2!1sen!2sbd"
                    width="100%"
                    height="400" 
                    style={{border:"0"}} 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            <Footer />
        </>
    )
}
