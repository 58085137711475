import { Link } from "react-router-dom";
import footer_img from "../assets/Logo.png";
import card6 from "../assets/cards_png/amex.png";
import card4 from "../assets/cards_png/discover.png";
import card3 from "../assets/cards_png/jcb.png";
import card5 from "../assets/cards_png/maestro.png";
import card1 from "../assets/cards_png/mastercard.png";
import card2 from "../assets/cards_png/visa.png";

export default function Footer() {
    return (
        <div className="container-fluid footer_main">
            <div className="container footer_body">
                <div className="row d-flex justify-content-center align-items-start">
                    
                    <div className=" my-3 col-md-3 d-flex justify-content-center align-items-center">
                        <div className="footer_img d-flex justify-content-center align-items-center">
                            <img src={footer_img} alt="footer_img" />
                        </div>
                    </div>

                    <div className="my-3 col-md-3 d-flex justify-content-center align-items-center footer_text quick_links">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h3>QUICK LINKS</h3>
                            <Link to={'/about-us'}><span>About Us</span></Link>
                            <Link to={'/terms-and-conditions'}><span>Terms & Conditions</span></Link>
                            <Link to={'/return-policy'}><span>Return Policy</span></Link>
                            <Link to={'/refund-policy'}><span>Refund Policy</span></Link>
                            <Link to={'/privacy-policy'}><span>Privacy Policy</span></Link>
                        </div>
                    </div>
                    
                    <div className="my-3 col-md-3 d-flex justify-content-center align-items-center footer_text">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h3>CONTACT US</h3>
                            <span>00393286076514</span>
                            <span>hello@sightseeingroma.com</span>
                            <span style={{textAlign: "center"}}>Piazza d'Aracoeli, 8, 00186 Roma RM, Italy</span>
                        </div>
                    </div>

                    <div className="my-3 col-md-3 d-flex flex-column justify-content-center align-items-center footer_text">
                        <h3>WAYS YOU CAN PAY</h3>
                        <div className="f-flex flex-row justify-content-start flex-wrap footer_cards">
                            <img src={card1} alt="mastercard" />
                            <img src={card2} alt="visacard" />
                            <img src={card3} alt="jbc" /> <br />
                            <img src={card4} alt="discover" />
                            <img src={card5} alt="maestro" />
                            <img src={card6} alt="amex" />
                        </div>
                    </div>

                </div>

                <div className="row d-flex justify-content-center align-items-center text-center mt-3 footer_credit">
                    <span>© All Rights Reserved to Sightseeing Roma</span>
                    <span>Designed & Developed by <a target="blank" href="https://www.facebook.com/mahin1319/">Mahian Mahin</a></span>
                </div>
            </div>
        </div>
    )
}