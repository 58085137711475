import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/navigation"
import "swiper/css/pagination"

import { useNavigate } from "react-router-dom"
import { EffectCoverflow, Pagination } from "swiper/modules"
import sale_image from "../assets/sale.png"
import { baseUrlHashless } from "../utilities/Utilities"
import Card from "./Card"

export default function Slider({packages}) {
    const navigate = useNavigate();

    return (
        <>

        <div className="new_heading d-flex justify-content-center"><p>why buy from us</p></div>

        <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <div className="slide_content">
            {packages.length !== 0 && packages.map(package_item => package_item.is_featured &&
                <SwiperSlide> 
                    <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}>
                        
                        <div onClick={() => navigate(`/packages/${package_item.ticket_type}/${package_item.company}/`)} className="my-2 card_button d-flex justify-content-center">
                            <a>similar options</a>
                        </div>

                        <div className="sale_section">
                            <img className='' src={sale_image} alt="sale" />
                            <div><p className=''>Starting from €{package_item.adult_price}</p></div>
                        </div>
                    </Card>
                </SwiperSlide>
            )}
        </div>
    </Swiper>
    </>
    )
}