import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";
import scrollToTop from "../utilities/scrollToTop";

export default function Dashboard() {
    scrollToTop();
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`${baseUrl}dashboard/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.localStorage['access']}`,
            },
        }).then(response => {
            if (response.status === 401) {
                navigate('/registration/')
            } else {
                return response.json()
            }
        }).then(data => setData(data.data))
    }, [])
    
    return(
        <>
        
        <Header />

        <div className="container dashboard_main my-5">
            {data && data.length !== 0 ? <h1>Purchased Tickets</h1> : <h1>No Purchased Tickets Yet!</h1>}

            <table className="my-4">
                {data && data.length !== 0 && <tr>
                    <th>Selected Date</th>
                    <th>Package</th>
                    <th>Total Price</th>
                    <th>Ticket Claimed</th>
                    <th>QR Code</th>
                </tr>}
                
                {data && data.map(item => <>
                    <tr>
                        <td>{item.selected_date === '' ? "N/A" : item.selected_date}</td>
                        <td>{item.package}</td>
                        <td>€{item.total_price}</td>
                        <td>{item.qr_code_scanned ? "Yes" : "No"}</td>
                        <td><a href={`${baseUrlHashless}${item.qr_code}`} download>Save</a></td>
                    </tr>    
                </>)}
            </table>
        </div>

        {/* <Footer /> */}

        </>
    )
}