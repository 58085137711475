import { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css'
import { useNavigate } from 'react-router-dom'
import Card from '../components/Card'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Slider from '../components/SLider'
import Title from '../components/Title'
import '../style/style.css'
import { baseUrl, baseUrlHashless } from '../utilities/Utilities'
import scrollToTop from '../utilities/scrollToTop'


export default function Home() {
    
    scrollToTop();
    var navigate = useNavigate();


    const [busPackages, setBusPackages] = useState([])
    const [museumPackages, setMuseumPackages] = useState([])
    const [folders, setFolders] = useState([])

    useEffect(() => {
        fetch(baseUrl + "packages/")
            .then(response => response.json())
            .then(data => {
                setBusPackages(data.bus_data);
                setMuseumPackages(data.museum_data);
                setFolders(data.folders);
        })
    }, [])

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        },
        mobile2: {
          breakpoint: { max: 430, min: 0 },
          items: 2
        }
    };

    return (
        <>

        <Header />
        <Hero />

        <div className="main_card_container container">

            <Slider packages={busPackages} />

            {folders.length !== 0 ? folders.map(item => <>
                <Title text={item.name} />

                <div className="my-3">
                <Carousel infinite={true} autoPlaySpeed={5000} autoPlay={true} responsive={responsive}>
                    {/* {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.folder === item.id && <Card has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}         */}
                    {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.folder === item.id && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
                </Carousel>
                </div>

            </>) : <span>Loading...</span>}
            
            {/* <Title text="MUSEUM & TOURS" />

            <div className="my-5 cards_section d-flex flex-row flex-wrap">

                {museumPackages.length === 0? <h5>Loading packages...</h5> : museumPackages.length !== 0 && museumPackages.map(item => <Card has_dates={true} key={item.id} package_tag={item.package_tag} image={baseUrlHashless + item.image_big} type={item.type} name={item.title} time={item.duration} striked={item.off_price} real={item.adult_price} />)}

            </div> */}
        </div>

        <Footer />
        </>
    )
}