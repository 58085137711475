import { useEffect, useState } from 'react';
import custom_loader from "../assets/custom_loader.gif";
import fast_forward from "../assets/fast-forward.png";
import { baseMediaUrl, baseUrl } from '../utilities/Utilities';

export default function Hero() {
    const [heroImage, setHeroImage] = useState('');


    useEffect(() => {
        fetch(baseUrl + "hero_image/")
            .then(response => response.json())
            .then(data => {
                setHeroImage(data.data.hero_image[0].fields.image);
            })
    }, []);

    return (
        <>
        <div className="container-fluid hero">
            <div className="wrapper">
                {heroImage ? <img src={baseMediaUrl+heroImage} alt="hero_image" />: <img src={custom_loader} alt="hero_image" />}
                <div className="overlay"></div>
                <div className="hero_text d-flex justify-content-center align-items-center">
                    <h1>Create Memories With Us</h1>
                </div>

            </div>
            <div className="hero_icon d-flex justify-content-center align-items-center pt-3 mb-1">
                <img style={{height: "25px", width: "25px"}} className='hero_icon_image' src={fast_forward} alt="fast_forward_icon" />
            </div>
        </div>
        </>
    )
}

