import { useState } from "react";
import { useNavigate } from "react-router-dom";
import loading_img from "../assets/loading.gif";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl } from "../utilities/Utilities";


export default function Registration() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = (event) => {
        setErrorMessage('');
        setError(false);

        event.preventDefault();
        setLoading(true);

        const header = {
            mode: 'cors',
            method: 'POST',
            headers: {
                // "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                full_name: fullName,
                email: email,
                password: password
            })
        };

        if (password === confirmPassword) {
            fetch(`${baseUrl}register/`, header)
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setErrorMessage(data.error);
                        setError(true);
                        setLoading(false);
                    }

                    if (data.registered === true) {
                        navigate('/login/');
                    }
                })
        } else {
            setErrorMessage("Passwords didn't match!");
            setError(true);
            setLoading(false);

            setFullName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
        }
    }


    return(
        <>
        
        <Header />

        
        <div className="container form_section d-flex flex-column justify-content-center align-items-center my-5">
            <h1>Registration</h1>

            <form className="form-group" onSubmit={handleClick}>
                <input type="text" onChange={event => setFullName(event.target.value)}  value={fullName} required className="form-control" placeholder="Username" />
                <input type="email" value={email} onChange={event => setEmail(event.target.value)} required className="form-control" placeholder="Email address" />
                <input type="password" onChange={event => setPassword(event.target.value)} value={password} required className="form-control" placeholder="Password" />
                <input type="password" onChange={event => setConfirmPassword(event.target.value)} value={confirmPassword} required className="form-control" placeholder="Confirm password" />
                {error && <p id="error_message">{errorMessage}</p>                }
                
                <button disabled={loading} type="submit">{loading ? <img src={loading_img} alt="loading" /> : `submit`}</button>
            </form>

            <div className="footnote">Already registered? <strong onClick={() => navigate(`/login/`)}>Login</strong></div>
        </div>


        <Footer />

        </>
    )
}