import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useParams } from "react-router-dom";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Title from "../components/Title";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";
import scrollToTop from "../utilities/scrollToTop";

export default function TicketList() {
    const {type, company} = useParams();
    const [busPackages, setBusPackages] = useState([]);

    useEffect(() => {
        scrollToTop();

        fetch(baseUrl + "packages/")
            .then(response => response.json())
            .then(data => {
                setBusPackages(data.bus_data);
            })
    }, [])

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        },
        mobile2: {
          breakpoint: { max: 430, min: 0 },
          items: 2
        }
    };

    return (
        <>
        <Header />

        <div className="container my-3">
            <div className="my-3">
                <Title text="same categories" />
            </div>

            <Carousel infinite={true} autoPlaySpeed={5000} autoPlay={true} responsive={responsive}>
                    {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.company === company && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
            </Carousel>
            
            {/* <div className="d-flex justify-content-start align-items-start flex-wrap">
                    {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.company === company && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
            </div> */}
            
            <div className="my-3">
                <Title text="similar options" />
            </div>

            <Carousel infinite={true} autoPlaySpeed={5000} autoPlay={true} responsive={responsive}>
                {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.ticket_type === type && package_item.company !== company && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
            </Carousel>
            
            {/* <div className="d-flex justify-content-start align-items-start flex-wrap">
                {busPackages.length === 0 ? <h5>Loading packages...</h5> : busPackages.length !== 0 && busPackages.map(package_item => package_item.ticket_type === type && package_item.company !== company && <Card card_id={package_item.id} has_dates={false} key={package_item.id} package_tag={package_item.package_tag} image={baseUrlHashless + package_item.image_big} type={package_item.type} name={package_item.title} time={package_item.duration} striked={package_item.off_price} real={package_item.adult_price}></Card>)}
            </div> */}

        </div>

        <Footer />
        
        </>
    )
}