import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/Logo.png';
import toggle from '../assets/toggle.png';
import useAuthenticate from '../hooks/useAuthenticate';
import '../style/style.css';


export default function Header() {
    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useAuthenticate(setIsLoggedIn);

    function logout() {
        window.localStorage.clear();
        window.location.reload();

        navigate('/');
    }


    const toggleHandler = () => {
        setVisible(!visible);
        
    }

    return (
        <>
            <div className="yellow_bar"></div>
            <div className="container-fluid navbar_bg">
                <div className="container d-flex flex-row justify-content-between align-items-center navbar_body">
                    <div className="brand">
                        <Link to={'/'}><img src={logo} alt="logo" /></Link>
                    </div>

                    <div className="nav_elements d-flex flex-row justify-content-between align-items-start">
                        <Link to={isLoggedIn ? '/dashboard/' : '/login/'}>
                            <div className="d-flex justify-content-center flex-row align-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"/></svg>
                                <span>manage bookings</span>
                            </div>
                        </Link>

                        <div className="vertical_bar"></div>
                        
                        <Link to={'/agent-point/'}>
                            <div className="d-flex justify-content-center flex-row align-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#ffffff" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg> 
                                <span>Find our agent point</span>
                            </div>
                        </Link>

                        <div className="vertical_bar"></div>
                        
                        <Link to={isLoggedIn ? '/dashboard/' : '/login/'}>
                            <div className="d-flex justify-content-center flex-row align-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                                <span>profile</span>
                            </div>
                        </Link>

                        {isLoggedIn && <div onClick={() => logout()} className="logout_section d-flex justify-content-center flex-row align-items-start">
                        <div className="vertical_bar"></div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>
                        {/* <span>logout</span> */}
                        </div>}
                        
                        <div className="toggle">
                            <img src={toggle} alt="hamburger" onClick={toggleHandler} />
                        </div>
                        
                    </div>
                </div>

            </div>
            
            {/* navbar tray menu */}

            <div className="container-fluid d-flex align-items-center navbar_tray">
                <div className="container navbar_tray_body d-flex flex-row justify-content-between align-items-center w-100">
                    <Link to={'/'}>home</Link>
                    <Link to={'/about-us/'}>about us</Link>
                    <Link to={'/terms-and-conditions/'}>terms & conditions</Link>
                    <Link to={'/return-policy/'}>return policy</Link>
                    <Link to={'/refund-policy/'}>refund policy</Link>
                </div>
            </div>

            {/* mobile-navbar */}

            <div id="mobile" className={` ${visible ? '' : 'invisible'} mobile_navbar shadow auth_buttons d-flex flex-column justify-content-center align-items-center`}>
                <div className="inner_mobile_section d-flex flex-column justify-content-center align-items-center">
                    <span onClick={() => navigate('/')}>Home</span>
                    <div className="horizontal_line"></div>

                    {isLoggedIn && <><span onClick={() => navigate('/dashboard/')}>Profile</span><div className="horizontal_line"></div></>}

                    <span onClick={() => navigate('/about-us')}>About Us</span>
                    <div className="horizontal_line"></div>

                    <span onClick={() => navigate('/terms-and-conditions/')}>Terms & Conditions</span>
                    <div className="horizontal_line"></div>

                    <span onClick={() => navigate('/return-policy/')}>Return Policy</span>
                    <div className="horizontal_line"></div>

                    <span onClick={() => navigate('/refund-policy/')}>Refund Policy</span>
                    <div className="horizontal_line"></div>

                    <span onClick={() => navigate('/privacy-policy/')}>Privacy Policy</span>
                </div>

                <div className="below_buttons d-flex justify-content-center aligh-contents-center">
                    {isLoggedIn ? <><Link to={'/dashboard/'}><button>Dashboard</button></Link>
                    <Link><button onClick={() => logout()} >Logout</button></Link></> : 
                    
                    <><Link to={'/login/'}><button>Login</button></Link>
                    <Link to={'/registration/'}><button>Register</button></Link></>}
                </div>
            </div>
        </>
    )
}

