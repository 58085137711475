export default function MobileButtonSet({state, setState}) {
    function increase() {
        setState(state + 1);
    }

    function decrease() {
        if (!state <= 0) {
            setState(state - 1);
        }
    }

    return (
        <div className="mobile_button_set d-flex flex-column justify-content-between">
            <div onClick={increase} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
                    <path fill="#a30000" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
                </svg>
            </div>

            <div onClick={decrease} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
                    <path fill="#a30000" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                </svg>
            </div>
        </div>
    )
}