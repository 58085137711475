import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link, useNavigate, useParams } from "react-router-dom";
import check from "../assets/check.png";
import qr_loader from "../assets/qr_loader.gif";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";

export default function Success() {
    const [url, setUrl] = useState('')

    const navigate = useNavigate()
    const { unique_id } = useParams();

    useEffect(() => {
        fetch(`${baseUrl}success/${unique_id}/`, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${window.localStorage['access']}`,
            }
        })
        .then(response => {
            if (response.status === 401) {
                navigate('/login/')
            } else {
                return response.json()
            }
        })
        .then(data => setUrl(data.url))

        fetch(`${baseUrl}qr/auth/${unique_id}/`)
            .then(response => response.json())
            .then(data => {
                console.log(data.data);

                const itemList = [
                    {
                      id: data.data.id,
                      name: data.data.package,
                      brand: "Sightseeingroma",
                      category: "Tourism",
                      price: data.data.total_price,
                      quantity: 1
                    },
                  ];
                  
                  ReactGA.event({
                    category: 'Ecommerce',
                    action: 'purchase',
                    transaction_id: data.data.id,
                    shipping: 0,
                    value: itemList.reduce((sum, item) => sum + item.price * item.quantity, 0),
                    currency: 'EUR',
                    'items': itemList,
                  });
            })
    }, [])
    
    return(
        <>
        <Header />

        <div className="container-fluid d-flex flex-column justify-content-center align-items-center my-5">
            <div className="success_box d-flex flex-column justify-content-center align-items-center">
                <img src={check} alt="icon" />
                <h1>payment successful!</h1>
            </div>

            <div className="qr_code_box d-flex flex-column justify-content-center align-items-center">
                { url ? <img src={`${baseUrlHashless}${url}`} alt="qr_code" /> : <img src={qr_loader} alt="qr_code" />}
                <a href={`${baseUrlHashless}${url}`} download>save</a>
            </div>

            <div className="mt-4"><p><b>Show this QR Code to the below meeting point</b></p></div>
            <Link to={'/agent-point/'}><h5>Piazza d'Aracoeli, 8, 00186 Roma RM</h5></Link>

        </div>

        <Footer />
        </>
    )
}