import React, { useState } from 'react';
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/style/style.css';
import '../src/style/t_media.css';
import AboutUs from './pages/AboutUs';
import AgentPoint from './pages/AgentPoint';
import Cancel from './pages/Cancel';
import Dashboard from './pages/Dashboard';
import Details from './pages/Details';
import FolderPackages from './pages/FolderPackages';
import Home from './pages/Home';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProcessTicketsV2 from './pages/ProcessTicketsV2';
import RefundPolicy from './pages/RefundPolicy';
import Registration from './pages/Registration';
import ReturnPolicy from './pages/ReturnPolicy';
import Success from './pages/Success';
import TermsAndConditions from './pages/TermsAndConditions';
import TicketList from './pages/TicketList';

export const UtilitiesContext = React.createContext();

ReactGA.initialize("G-4SE3FTRWJW");

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/details/:status/:packageId/' element={<Details />} />
          <Route path='/:code/' element={<ProcessTicketsV2 />} />
          <Route path='/success/:unique_id/' element={<Success />} />
          <Route path='/cancel/' element={<Cancel />} />
          <Route path='/login/' element={<Login />} />
          <Route path='/registration/' element={<Registration />} />
          <Route path='/dashboard/' element={<Dashboard />} />
          <Route path='/privacy-policy/' element={<PrivacyPolicy />} />
          <Route path='/return-policy/' element={<ReturnPolicy />} />
          <Route path='/refund-policy/' element={<RefundPolicy />} />
          <Route path='/about-us/' element={<AboutUs />} />
          <Route path='/terms-and-conditions/' element={<TermsAndConditions />} />
          <Route path='/agent-point/' element={<AgentPoint />} />
          <Route path='/packages/:id/' element={<FolderPackages />} />
          <Route path='/packages/:type/:company/' element={<TicketList />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
